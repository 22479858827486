import React from "react";
import { graphql } from "gatsby";
import { ContactoPage } from "../../components/Contacto";
import Layout from "../../components/Shared/Layout";

const ContactoTemplate = ({ data }) => {
  const {
    siteConfig,
    page: { tabs, slug },
  } = data;
  const { seo } = tabs;
  seo.slug = slug;

  return (
    <Layout siteConfig={siteConfig} seoPage={seo}>
      <ContactoPage />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ContactoPageQuery($slug: String!) {
    siteConfig: sanitySiteConfig {
      lang
      title
      description
      url
      mainImage {
        asset {
          url
        }
      }
    }
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      tabs {
        seo {
          meta_title
          meta_description
          article
          seo_image {
            asset {
              url
            }
          }
          includeInSitemap
        }
      }
    }
  }
`;

export default ContactoTemplate;
