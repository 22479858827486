import React, { useState } from "react";

interface TextAreaType {
  rows?: number;
  placeholder: string;
  required: boolean;
  onChanged: (text: any) => void;
}

export const TextArea: React.FC<TextAreaType> = ({
  onChanged,
  rows = 3,
  placeholder,
  required,
}) => {
  const [text, setText] = useState("");

  const onChange = (value) => {
    setText(value);
    onChanged(value);
  };

  return (
    <textarea
      name="mensaje"
      id="mensaje"
      rows={rows}
      placeholder={placeholder}
      value={text}
      onChange={({ target: { value } }) => onChange(value)}
      maxLength={500}
      required={required}
    />
  );
};
