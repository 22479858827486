import React, { useState } from "react";
const re = /^[0-9\b]+$/;

interface PhoneInputType {
  onPhoneChanged: (phone: any) => void;
}

export const PhoneInput: React.FC<PhoneInputType> = ({ onPhoneChanged }) => {
  const [phone, setPhone] = useState("");

  const onChange = (value) => {
    if (value === "" || re.test(value)) {
      setPhone(value);
      onPhoneChanged(value);
    }
  };

  return (
    <input
      type="tel"
      value={phone}
      onChange={({ target: { value } }) => onChange(value)}
      name="phone"
      id="phone"
      placeholder="teléfono."
      required
    />
  );
};
