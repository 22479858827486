import { navigate } from "gatsby";
import React, { useState } from "react";
import { contactService } from "../../../services/contact.service";
import { EmailInput } from "../../Shared/Inputs/EmailInput/email-input.component";
import { NameInput } from "../../Shared/Inputs/NameInput/name-input.component";
import { PhoneInput } from "../../Shared/Inputs/PhoneInput/phone-input.component";
import { TextArea } from "../../Shared/Inputs/TextArea/text-area.component";
import { TextInput } from "../../Shared/Inputs/TextInput/text-input.component";
import "./inscribe-form.scss";

interface InscribeFormProps {}

export const InscribeForm: React.FC<InscribeFormProps> = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [pais, setPais] = useState<string>("");
  const [phone, setPhone] = useState<number>();
  const [personalData, setPersonalData] = useState<string>("");
  const [enterprise, setEnterprise] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [tyc, setTyc] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const isDisable = (): boolean =>
    !name ||
    !email ||
    !pais ||
    !phone ||
    !personalData ||
    !enterprise ||
    !message ||
    !tyc;

  const onSubmit = (e): void => {
    e.preventDefault();
    setLoading(true);

    contactService
      .sendEmail({
        name,
        email,
        pais,
        phone,
        personalData,
        enterprise,
        message,
      })
      .then(() => {
        setLoading(false);
        navigate("/exito");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="inscribe-form">
      <h1>Queremos conocer tu proyecto</h1>

      <form onSubmit={(e) => onSubmit(e)}>
        <NameInput onNameChanged={(name) => setName(name)} />
        <EmailInput onEmailChanged={(email) => setEmail(email)} />
        <TextInput
          onTextChanged={(text) => setPais(text)}
          placeholder="pais."
        />
        <PhoneInput onPhoneChanged={(phone) => setPhone(phone)} />
        <TextInput
          onTextChanged={(text) => setPersonalData(text)}
          placeholder="datos personales"
        />
        <TextInput
          onTextChanged={(text) => setEnterprise(text)}
          placeholder="Tu empresa."
        />
        <TextArea
          placeholder="¿Algo que quieras agregar?"
          rows={5}
          onChanged={(text) => setMessage(text)}
          required={true}
        />

        <div className="bottom-content">
          <div className="tyc">
            <label htmlFor="tyc">
              Acepto terminos y condiciones
              <input
                type="checkbox"
                id="tyc"
                onChange={(event) => setTyc(event.target.checked)}
              />
              <span className="checkmark" />
            </label>
          </div>
          <button disabled={isDisable() || loading}>
            {!loading ? "ENVIAR" : "ENVIANDO..."}
          </button>
        </div>
      </form>
    </div>
  );
};
