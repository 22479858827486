import React, { useState } from "react";

interface EmailInputType {
  onEmailChanged: (name: any) => void;
}

export const EmailInput: React.FC<EmailInputType> = ({ onEmailChanged }) => {
  const [email, setEmail] = useState<string>("");

  const onChange = (value) => {
    setEmail(value);
    onEmailChanged(value);
  };

  return (
    <input
      type="email"
      value={email}
      onChange={({ target: { value } }) => onChange(value)}
      name="email"
      id="email"
      placeholder="mail."
      maxLength={60}
      required
    />
  );
};
