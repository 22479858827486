import React, { useState } from "react";

interface TextInputType {
  onTextChanged: (name: any) => void;
  placeholder: string;
}

export const TextInput: React.FC<TextInputType> = ({
  onTextChanged,
  placeholder,
}) => {
  const [text, setText] = useState("");

  const onChange = (value) => {
    setText(value);
    onTextChanged(value);
  };

  return (
    <input
      type="text"
      value={text}
      onChange={({ target: { value } }) => onChange(value)}
      name="text"
      id="text"
      placeholder={placeholder}
      required
    />
  );
};
