import React, { useState } from "react";
const re = /^[A-zÀ-ú\s|´`]+$/;

interface NameInputType {
  onNameChanged: (name: any) => void;
}

export const NameInput: React.FC<NameInputType> = ({ onNameChanged }) => {
  const [name, setName] = useState("");

  const onChange = (value) => {
    if (value === "" || re.test(value)) {
      setName(value);
      onNameChanged(value);
    }
  };

  return (
    <input
      type="text"
      value={name}
      onChange={({ target: { value } }) => onChange(value)}
      name="name"
      id="name"
      placeholder="nombre/apellido."
      maxLength={50}
      required
    />
  );
};
