import axios from "axios";
import { clientConfig } from "./client-config";

export const contactService = {
  sendEmail(data: any) {
    return axios
      .post(`${clientConfig.apiUrl}/mail/send`, {
        params: {
          data,
        },
      })
      .then((response) => response.data.data)
      .catch((error) => {
        console.log(error);
      });
  },
};
