import React from "react";
import { SiteConfig } from "../../interfaces/site";
import { InscribeForm } from "./InscribeForm/inscribe-form.component";
import "./contacto.scss";

interface ContactoProps {
  siteConfig?: SiteConfig;
}

export const ContactoPage: React.FC<ContactoProps> = ({ siteConfig }) => {
  return (
    <div className="contacto-page">
      <div className="container">
        <div className="container-grid">
          <div className="first">
            <img
              src="https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1652276575/We-Are/contacto_jicose.jpg"
              alt="Contacto"
              className="img-fluid w-100"
            />
          </div>
          <div className="last">
            <InscribeForm />
          </div>
        </div>
      </div>
    </div>
  );
};
